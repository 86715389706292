.boton {
    cursor: pointer;
    font: inherit;
    color: white;
    background-color: #9f5ccc;
    border: 1px solid #9f5ccc;
    border-radius: 4px;
    padding: 0.5rem 2.5rem;
    margin:3px;
    margin-top: 5px;
  }

  .etiqueta {
    color: #9f5ccc;
    font-size: 10px;
    font-weight: bold;
    text-transform: uppercase;
  }

  .updatepwd{
    color: #9f5ccc;
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;
  }
  @media screen and (min-width: 800px) {
  .showHide{
    margin-top: -30px;
    margin-right: 25px;
  }
}

.showHide{
  color:#9f5ccc;
  float: right;
  font-size: 14px;
}

  .showHide:hover{
    color:#cc5c5c;
    font-weight:bold;
    text-decoration: underline;
    cursor: pointer;
  }