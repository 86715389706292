@import url(https://fonts.googleapis.com/css2?family=Lato:wght@700&family=Open+Sans:wght@400;700&display=swap);
* {
  box-sizing: border-box;
}

body {
  font-family: 'Open Sans', 'Lato', sans-serif;
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Lato', sans-serif;
}
.AuthForm_auth__eCNZi {
  margin: 3rem auto;
  width: 95%;
  max-width: 25rem;
  border-radius: 6px;
  background-color: #38015c;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  padding: 1rem;
  text-align: center;
  color: white;
}

.AuthForm_auth__eCNZi h1 {
  text-align: center;
  color: white;
}

.AuthForm_control__gf06f {
  margin-bottom: 0.5rem;
}

.AuthForm_control__gf06f label {
  display: block;
  color: white;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.AuthForm_control__gf06f input {
  font: inherit;
  background-color: #f1e1fc;
  color: #38015c;
  border-radius: 4px;
  border: 1px solid white;
  width: 100%;
  text-align: left;
  padding: 0.25rem;
}

.AuthForm_actions__3k9P7 {
  margin-top: 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.AuthForm_actions__3k9P7 button {
  cursor: pointer;
  font: inherit;
  color: white;
  background-color: #9f5ccc;
  border: 1px solid #9f5ccc;
  border-radius: 4px;
  padding: 0.5rem 2.5rem;
}

.AuthForm_actions__3k9P7 button:hover {
  background-color: #873abb;
  border-color: #873abb;
}

.AuthForm_actions__3k9P7 .AuthForm_toggle__3r-MF {
  margin-top: 1rem;
  background-color: transparent;
  color: #9f5ccc;
  border: none;
  padding: 0.15rem 1.5rem;
}

.AuthForm_actions__3k9P7 .AuthForm_toggle__3r-MF:hover {
  background-color: transparent;
  color: #ae82cc;
}
.b-example-divider {
    height: 3rem;
    background-color: rgba(0, 0, 0, .1);
    border: solid rgba(0, 0, 0, .15);
    border-width: 1px 0;
    box-shadow: inset 0 .5em 1.5em rgba(0, 0, 0, .1), inset 0 .125em .5em rgba(0, 0, 0, .15);
  }
  
  .form-control-dark {
    color: #fff;
    background-color: var(--bs-dark);
    border-color: var(--bs-gray);
  }
  .form-control-dark:focus {
    color: #fff;
    background-color: var(--bs-dark);
    border-color: #fff;
    box-shadow: 0 0 0 .25rem rgba(255, 255, 255, .25);
  }
  
  .bi {
    vertical-align: -.125em;
    fill: currentColor;
  }
  
  .text-small {
    font-size: 85%;
  }
  
  .dropdown-toggle {
    outline: 0;
  }
  .bg-dark {
      background-color: #38015c;
  }
  .bg-purple {
      background-color: #38015c;
  }
  
  .navbar-light .navbar-brand {
      color: #38015c;
  }
  
  .navbar-light .navbar-nav .nav-link.active, .navbar-light .navbar-nav .show>.nav-link {
      color: red;
  }
  
  .navbar-light .navbar-nav .nav-link {
      color: #38015c;
  }
  
  .navbar-light .navbar-nav .nav-link:hover {
      color: red;
  }
  
  .btn-outline-success {
      color: #38015c;
      border-color: #38015c;
  }
  
  .btn-outline-success:hover {
      color: #FFFFFF;
      background-color: #38015c;
      border-color: #38015c;
  }
  
  .btn-primary {
      color: #FFFFFF;
      background-color: #38015c;
      border-color: #38015c;
  }
  
  .btn-primary:hover {
      color: #FFFFFF;
      background-color: red;
      border-color: #38015c;
  }
  
  .dropdown-item {
      display: block;
      width: 100%;
      padding: 0.25rem 1rem;
      clear: both;
      font-weight: 400;
      color: #ffffff;
      text-align: inherit;
      text-decoration: none;
      white-space: nowrap;
      /*background-color: transparent;*/
      border: 0;
      background-color: #38015c;
  }
  
  .dropdown-item:hover {
      display: block;
      width: 100%;
      padding: 0.25rem 1rem;
      clear: both;
      font-weight: 400;
      color: #ffffff;
      text-align: inherit;
      text-decoration: none;
      white-space: nowrap;
      /*background-color: transparent;*/
      border: 0;
      background-color: red;
  }
  
  .dropdown-menu {
      color: #ffffff;
      background-color: #38015c;
  }
  
  .bg-light2 {
      background-color: #71cee4!important;
       color: #ffffff;
  }
  
  a {
      color: black;
      text-decoration:none;
  }
  
  a i {
      color: purple;
      text-decoration:none;
  }
  
  a:hover {
      color: red;
      text-decoration:underline;
  }
  
  a i:hover {
      color: #71F40B;
      text-decoration:none;
  }
.Card_card__d3EoH {
  background: white;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  border-radius: 10px;
}

.Button_button__1GqJX {
  font: inherit;
  border: 1px solid #4f005f;
  background: #4f005f;
  color: white;
  padding: 0.25rem 1rem;
  cursor: pointer;
}

.Button_button__1GqJX:hover,
.Button_button__1GqJX:active {
  background: #741188;
  border-color: #741188;
}

.Button_button__1GqJX:focus {
  outline: none;
}

.PdfModal_backdrop__22vTu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 10;
  background: rgba(0, 0, 0, 0.75);
}

.PdfModal_modal__1THdk {
  position: fixed;
  top: 10vh;
  left: 10%;
  width: 80%;
  z-index: 100;
  overflow: hidden;
}

.PdfModal_header__C4Kwf {
  background: #4f005f;
  padding: 1rem;
}

.PdfModal_header__C4Kwf h2 {
  margin: 0;
  color: white;
}

.PdfModal_header__C4Kwf h4 {
  margin: 0;
  color: white;
}

.PdfModal_content__h1knt {
  padding: 1rem;
}

.PdfModal_actions__2YSQo {
  padding: 1rem;
  display: flex;
  justify-content: flex-end;
}

/*@media (min-width: 768px) {
  .modal {
    left: calc(50% - 20rem);
    width: 40rem;
  }
}*/

.boton {
    cursor: pointer;
    font: inherit;
    color: white;
    background-color: #9f5ccc;
    border: 1px solid #9f5ccc;
    border-radius: 4px;
    padding: 0.5rem 2.5rem;
    margin:3px;
    margin-top: 5px;
  }

  .etiqueta {
    color: #9f5ccc;
    font-size: 10px;
    font-weight: bold;
    text-transform: uppercase;
  }

  .updatepwd{
    color: #9f5ccc;
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;
  }
  @media screen and (min-width: 800px) {
  .showHide{
    margin-top: -30px;
    margin-right: 25px;
  }
}

.showHide{
  color:#9f5ccc;
  float: right;
  font-size: 14px;
}

  .showHide:hover{
    color:#cc5c5c;
    font-weight:bold;
    text-decoration: underline;
    cursor: pointer;
  }
