.b-example-divider {
    height: 3rem;
    background-color: rgba(0, 0, 0, .1);
    border: solid rgba(0, 0, 0, .15);
    border-width: 1px 0;
    box-shadow: inset 0 .5em 1.5em rgba(0, 0, 0, .1), inset 0 .125em .5em rgba(0, 0, 0, .15);
  }
  
  .form-control-dark {
    color: #fff;
    background-color: var(--bs-dark);
    border-color: var(--bs-gray);
  }
  .form-control-dark:focus {
    color: #fff;
    background-color: var(--bs-dark);
    border-color: #fff;
    box-shadow: 0 0 0 .25rem rgba(255, 255, 255, .25);
  }
  
  .bi {
    vertical-align: -.125em;
    fill: currentColor;
  }
  
  .text-small {
    font-size: 85%;
  }
  
  .dropdown-toggle {
    outline: 0;
  }
  .bg-dark {
      background-color: #38015c;
  }
  .bg-purple {
      background-color: #38015c;
  }
  
  .navbar-light .navbar-brand {
      color: #38015c;
  }
  
  .navbar-light .navbar-nav .nav-link.active, .navbar-light .navbar-nav .show>.nav-link {
      color: red;
  }
  
  .navbar-light .navbar-nav .nav-link {
      color: #38015c;
  }
  
  .navbar-light .navbar-nav .nav-link:hover {
      color: red;
  }
  
  .btn-outline-success {
      color: #38015c;
      border-color: #38015c;
  }
  
  .btn-outline-success:hover {
      color: #FFFFFF;
      background-color: #38015c;
      border-color: #38015c;
  }
  
  .btn-primary {
      color: #FFFFFF;
      background-color: #38015c;
      border-color: #38015c;
  }
  
  .btn-primary:hover {
      color: #FFFFFF;
      background-color: red;
      border-color: #38015c;
  }
  
  .dropdown-item {
      display: block;
      width: 100%;
      padding: 0.25rem 1rem;
      clear: both;
      font-weight: 400;
      color: #ffffff;
      text-align: inherit;
      text-decoration: none;
      white-space: nowrap;
      /*background-color: transparent;*/
      border: 0;
      background-color: #38015c;
  }
  
  .dropdown-item:hover {
      display: block;
      width: 100%;
      padding: 0.25rem 1rem;
      clear: both;
      font-weight: 400;
      color: #ffffff;
      text-align: inherit;
      text-decoration: none;
      white-space: nowrap;
      /*background-color: transparent;*/
      border: 0;
      background-color: red;
  }
  
  .dropdown-menu {
      color: #ffffff;
      background-color: #38015c;
  }
  
  .bg-light2 {
      background-color: #71cee4!important;
       color: #ffffff;
  }
  
  a {
      color: black;
      text-decoration:none;
  }
  
  a i {
      color: purple;
      text-decoration:none;
  }
  
  a:hover {
      color: red;
      text-decoration:underline;
  }
  
  a i:hover {
      color: #71F40B;
      text-decoration:none;
  }